<template>
	<div class="">
		<div id="">
			<el-image v-if="activeName=='first'" style="height: 400px; width: 100%"
				:src="require('@/assets/images/zm.jpg')"></el-image>
			<el-image v-else style="height: 400px; width: 100%" :src="require('@/assets/images/js.jpg')"></el-image>
		</div>
		<div class="page-content" style="margin-top: 40px;">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>翻译润色</el-breadcrumb-item>
					<el-breadcrumb-item>检索证明</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 40px;margin-top: 40px;">
				<el-tab-pane label="代开检索证明" name="first">
					<div id="" style="border: solid 1px #767288;margin: 40px auto;width: 80%;padding-top: 30px;">
						<div id="" style="width: 80%;margin: 40px auto;">
							<div id="">
								<p style="color: #333333;font-size: 24px;text-align: center;">论文收录、被引情况检索申请书</p>
								<el-divider></el-divider>
							</div>
							<div class="loading">
								<el-form :model="releaseForm" :inline="true" :rules="rules" ref="releaseForm">
									<el-form-item label="检索人" label-width="100px" required prop="searcher">
										<el-input v-model="releaseForm.searcher" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="检索单位" label-width="100px" required prop="searchUnit">
										<el-input v-model="releaseForm.searchUnit" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="联系邮箱" label-width="100px" required prop="Email">
										<el-input v-model="releaseForm.Email" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="联系电话" label-width="100px" required prop="phone">
										<el-input v-model="releaseForm.phone" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="检索目的" label-width="100px" prop="searchPurpose">
										<el-select v-model="releaseForm.searchPurpose" placeholder="检索目的">
											<el-option label="入职申请" value="入职申请"></el-option>
											<el-option label="优青申请" value="优青申请"></el-option>
											<el-option label="申报奖励" value="申报奖励"></el-option>
											<el-option label="申请专利" value="申请专利"></el-option>
											<el-option label="申报院士" value="申报院士"></el-option>
											<el-option label="职称评审" value="职称评审"></el-option>
											<el-option label="杰出青年" value="杰出青年"></el-option>
											<el-option label="特聘教授" value="特聘教授"></el-option>
											<el-option label="长江学者" value="长江学者"></el-option>
											<el-option label="珠江学者" value="珠江学者"></el-option>
											<el-option label="百人计划" value="百人计划"></el-option>
											<el-option label="333工程" value="333工程"></el-option>
											<el-option label="千人计划" value="千人计划"></el-option>
											<el-option label="其它" value="其它"></el-option>
										</el-select>
									</el-form-item>
									<br />
									<el-form-item label="收录检索" label-width="100px" prop="collectionRetrieval">
										<el-select v-model="releaseForm.collectionRetrieval" placeholder="收录检索">
											<el-option label="SCIE" value="SCIE"></el-option>
											<el-option label="EL" value="EL"></el-option>
											<el-option label="CSSCI" value="CSSCI"></el-option>
											<el-option label="CSCD" value="CSCD"></el-option>
											<el-option label="其它" value="其它"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="引用检索" label-width="100px" prop="referenceRetrieval">
										<el-select v-model="releaseForm.referenceRetrieval" placeholder="引用检索">
											<el-option label="SCIE" value="SCIE"></el-option>
											<el-option label="CSSCI" value="CSSCI"></el-option>
											<el-option label="CSCD" value="CSCD"></el-option>
											<el-option label="其它" value="其它"></el-option>
										</el-select>
									</el-form-item>
									<div id="">
										<p style="color: #333333;font-size: 24px;text-align: center;">文献清单</p>
										<el-divider></el-divider>
									</div>
									<el-form-item label="作者" label-width="100px" required prop="author">
										<el-input v-model="releaseForm.author" autocomplete="off"></el-input>
									</el-form-item>
									<br />
									<el-form-item label="论文标题" style="width: 98%" label-width="100px" required
										prop="title">
										<el-input v-model="releaseForm.title" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="期刊名称" label-width="100px" required prop="journalName">
										<el-input v-model="releaseForm.journalName" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="DOI" label-width="100px" required prop="doi">
										<el-input v-model="releaseForm.doi" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="年份" label-width="100px" required prop="yearY">
										<el-input v-model="releaseForm.yearY" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="卷期" label-width="100px" required prop="volume">
										<el-input v-model="releaseForm.volume" autocomplete="off"></el-input>
									</el-form-item>
									<br />
									<el-form-item style="width: 98%;margin-left: 5%;">
										<el-upload class="upload-demo" ref="upload"
											action="http://103.120.82.111:8066/upload/file" :on-remove="handleRemove"
											:on-success="onSuccess" multiple :limit="1" :on-exceed="handleExceed"
											:file-list="fileList">
											<el-button size="medium" type="info">上传清单</el-button>
											<template #tip>
												<div class="el-upload__tip" style="color: red">
													注：上传内容建议去除封面、目录、附录，仅上传正文
												</div>
											</template>
										</el-upload>
									</el-form-item>
									<el-form-item style="text-align: center; width: 100%;">
										<el-button type="primary" @click="onSubmit('releaseForm','open')"
											style="background-color: #18305b; width: 185px">提交</el-button>
									</el-form-item>
								</el-form>
							</div>
							<div>
								<p style="color: #c00000;">收费标准：</p>
								<div style="line-height: 8px;color: #333333;font-size: 14px;">
									<p>1. 论文检索费100元/篇</p>
									<p>2. JCR分区/中科院分区另加收5元</p>
									<p>3. 影响因子另加收5元</p>
									<p>4. 引用次数2元/条</p>
								</div>
								<div style="line-height: 8px;margin-top: 60px;color: #333333;font-size: 14px;">
									<p>检索周期：3-5个工作日</p>
									<p>邮寄方式：顺丰到付</p>
								</div>
								<p style="color: #c00000;">注意事项：</p>
								<div style="line-height: 8px;color: #333333;font-size: 14px;">
									<p>1. 如要求区分完全他引，请提供该篇论文的全部作者姓名</p>
									<p>2. 如是英文论文，请提供第一作者的中文姓名</p>
									<p>3. 上传原文格式必须为.pdf格式</p>
									<p>4. 检测结束后，工作人员会把检测报告发送至委托人邮箱，请注意查收</p>
								</div>
								<p style="color: #c00000;">保密协议：</p>
								<div style="line-height: 24px;color: #333333;font-size: 14px;">
									<p>IAMSET尊重知识产权，每一位IAMSET工作人员都经历过严格系统的职业道德培训以及签订了具有法律效应的《保密协议》。文件数据传输过程中经历多个安全层，确保文章信息不泄露。
									</p>

								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="查询检索状态" name="second">
					<div id="" style="border: solid 1px #767288;margin: 40px auto;width: 80%;padding-top: 10px;">
						<div id="" style="text-align: center;">
							<p>请填写以下信息，提交成功后，工作人员会帮您查询检索信息，检索结果同步发至您的邮箱，请注意查收。</p>
						</div>
						<div v-loading="loading">
							<el-form :model="releaseForm1" :inline="true" :rules="rules1" ref="releaseForm1">
								<el-form-item label="论文标题" style="width: 98%" label-width="100px" required prop="title">
									<el-input v-model="releaseForm1.title" autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item label="检索人" label-width="100px" required prop="searcher">
									<el-input v-model="releaseForm1.searcher" autocomplete="off"></el-input>
								</el-form-item>
								<br />
								<el-form-item label="邮箱" label-width="100px" required prop="Email">
									<el-input v-model="releaseForm1.Email" autocomplete="off"></el-input>
								</el-form-item>
								<br />
								<el-form-item label="联系电话" label-width="100px" required prop="phone">
									<el-input v-model="releaseForm1.phone" autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item style="text-align: center; width: 100%;">
									<el-button type="primary" @click="onSubmit('releaseForm1','query')"
										style="background-color: #18305b; width: 185px">提交</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div style="padding: 20px;">
							<p style="color: #c00000;">保密协议：</p>
							<div style="line-height: 24px;color: #333333;font-size: 14px;">
								<p>IAMSET尊重知识产权，每一位IAMSET工作人员都经历过严格系统的职业道德培训以及签订了具有法律效应的《保密协议》。文件数据传输过程中经历多个安全层，确保文章信息不泄露。
								</p>

							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				loading: false,
				activeName: "first",
				fileList: [],
				releaseForm: {
					searcher: "",
					searchUnit: "",
					Email: "",
					phone: "",
					searchPurpose: "",
					collectionRetrieval: "",
					referenceRetrieval: "",
					author: "",
					title: "",
					journalName: "",
					doi: "",
					yearY: "",
					volume: "",
					classification: "open",
					spareFieldO: ""
				},
				rules: {
					searcher: [{
						required: true,
						trigger: "blur",
						message: "请输入检索人"
					}, ],
					searchUnit: [{
						required: true,
						trigger: "blur",
						message: "请输入检索单位"
					}, ],
					Email: [{
						required: true,
						trigger: "blur",
						message: "请输入邮箱"
					}, ],
					phone: [{
						required: true,
						trigger: "blur",
						message: "请输入联系电话"
					}, ],

					author: [{
						required: true,
						trigger: "blur",
						message: "请输入作者"
					}, ],
					title: [{
						required: true,
						trigger: "blur",
						message: "请输入标题"
					}, ],
					journalName: [{
						required: true,
						trigger: "blur",
						message: "请输入期刊名称"
					}],
					doi: [{
						required: true,
						trigger: "blur",
						message: "请输入DOI"
					}],
					yearY: [{
						required: true,
						trigger: "blur",
						message: "请输入年份 "
					}],
					volume: [{
						required: true,
						trigger: "blur",
						message: "请输入卷期"
					}],

				},
				releaseForm1: {
					title: "",
					searcher: "",
					Email: "",
					phone: "",
					classification: "query"
				},
				rules1: {
					searcher: [{
						required: true,
						trigger: "blur",
						message: "请输入检索人"
					}, ],
					Email: [{
						required: true,
						trigger: "blur",
						message: "请输入邮箱"
					}, ],
					phone: [{
						required: true,
						trigger: "blur",
						message: "请输入联系电话"
					}, ],
					title: [{
						required: true,
						trigger: "blur",
						message: "请输入标题"
					}, ],
				},
			};
		},

		methods: {
			handleExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
					 files.length + fileList.length
					} 个文件`
				);
			},
			onSuccess(esponse, file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = this.fileList[0].response.data
			},
			handleRemove(file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = null
			},
			onSubmit(releaseForm, type) {
				let paramMap;
				if ('open' == type) {
					paramMap = this.releaseForm
				} else if ('query' == type) {
					paramMap = this.releaseForm1
				}
				this.$refs[releaseForm].validate((valid) => {
					if (valid) {
						let _this = this;
						_this.loading = true;
						$http
							.post("addIamsetSearchProof", paramMap, "loading")
							.then(function(res) {
								console.log(res)
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.$refs[releaseForm].resetFields();
											if ('open' == type) {
												_this.$refs. ['upload'].clearFiles();
												_this.fileList = [];
											}
											_this.loading = false;
											_this.fileList = [];
										},
									});
								}

							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
			resetForm(releaseForm) {
				this.$refs[releaseForm].resetFields();
			}
		},

	};
</script>

<style lang="scss" scoped>
	.num {
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
			linear-gradient(#d40016, #d40016);
		background-blend-mode: normal, normal;
	}

	.el-form-item {
		width: 48.5%;
	}
</style>
